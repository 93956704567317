import { detect } from 'detect-browser'
import { StaticImageData } from 'next/image'
import { IMedia } from '@/types'

export type TBasicSrc = string | StaticImageData
export type TResponsiveSrc = {
  [key: string]: TBasicSrc
  mobile: TBasicSrc
  desktop: TBasicSrc
}
export type TSrc = TBasicSrc | TResponsiveSrc

export type TResponsiveSize = {
  mobile: number | string
  desktop: number | string
}

export type TSize = number | string | TResponsiveSize

export type Media = {
  id: number
} & IMedia['attributes']

export type TImage = string | StaticImageData | IMedia | Media

export type TImages = TImage[]

export type ImageFormat = 'large' | 'medium' | 'small' | 'thumbnail' | 'xs'

export type ImageRelativeFormat = 'smallest' | 'largest'

export type ImageExtendedFormat = ImageFormat | ImageRelativeFormat

export const ARTWORK_IMG_PLACEHOLDER = '/files/image-placeholder.svg'

const formats = ['xs', 'thumbnail', 'small', 'medium', 'large']

export const isResponsiveSrc = (src: TSrc): src is TResponsiveSrc => {
  return (
    (src as TResponsiveSrc)?.mobile !== undefined &&
    (src as TResponsiveSrc)?.desktop !== undefined
  )
}

export const isResponsiveSize = (size: TSize): size is TResponsiveSize => {
  return (
    (size as TResponsiveSize)?.mobile !== undefined &&
    (size as TResponsiveSize)?.desktop !== undefined
  )
}

export const getSize = (
  size: TSize | undefined,
  device: 'mobile' | 'desktop'
) => {
  if (!size) return undefined

  return typeof size === 'object' ? size[device] : size
}

export const imgLoader = ({ src }: { src: string }) => src

export const imgIsUnoptimized = () => {
  const browser = detect()

  if (!browser?.name) return false

  return ['ios', 'safari'].includes(browser.name)
}

export const isMediaImage = (image: TImage): image is IMedia | Media => {
  if ((image as IMedia).attributes) {
    return Boolean(
      (image as IMedia).attributes.formats || (image as IMedia).attributes.url
    )
  }

  return Boolean((image as Media).formats || (image as Media).url)
}

export const getImageUrl = (
  image?: TImage,
  format: ImageExtendedFormat = 'thumbnail',
  placeholderUrl = ARTWORK_IMG_PLACEHOLDER
) => {
  console.log('image', image)

  if (!image) return placeholderUrl

  if (!isMediaImage(image)) {
    if (typeof image === 'string') return makeImageUrlAbsolute(image)
    else return makeImageUrlAbsolute(placeholderUrl)
  }

  const imgFormats =
    'formats' in image ? image.formats : (image as IMedia).attributes?.formats
  const imgUrl = 'url' in image ? image.url : (image as IMedia).attributes?.url

  if (!imgFormats && !imgUrl) return makeImageUrlAbsolute(placeholderUrl)

  const isRelativeFormat = ['smallest', 'largest'].includes(format)

  let formatUrl: string | undefined = ''

  if (isRelativeFormat && imgFormats) {
    const imageFormatsArr = Object.keys(imgFormats) as ImageFormat[]
    const sortedFormats = imageFormatsArr.sort(
      (a, b) => formats.indexOf(a) - formats.indexOf(b)
    )

    if (format === 'smallest') {
      const smallestFormat: ImageFormat = sortedFormats[0]
      formatUrl = imgFormats?.[smallestFormat]?.url
    }

    if (format === 'largest') {
      const largestFormat = sortedFormats[sortedFormats.length - 1]
      formatUrl = imgFormats?.[largestFormat]?.url
    }
  }

  if (!isRelativeFormat && imgFormats)
    formatUrl = imgFormats?.[format as ImageFormat]?.url

  if (formatUrl) return makeImageUrlAbsolute(formatUrl)

  if (imgUrl) return makeImageUrlAbsolute(imgUrl)

  return makeImageUrlAbsolute(placeholderUrl)
}

export const makeImageUrlAbsolute = (url: string) => {
  if (url.startsWith('http')) return url

  if (url.startsWith('cdn')) {
    return `https://${url}`
  }

  return `${process.env.NEXT_PUBLIC_DOMAIN}${url}`
}

export const getArtistImageUrl = (
  artistName: string,
  image?: TImage,
  format: ImageExtendedFormat = 'thumbnail'
) => getImageUrl(image, format, getArtistPlaceholder(artistName))

export const getArtistPlaceholder = (artistName: string) =>
  `https://eu.ui-avatars.com/api/?background=5907DE&color=fff&&size=256&name=${artistName}`
