import { AccessProvider } from 'contexts/providers/AccessProvider'
import { AuthProvider } from 'contexts/providers/AuthProvider'
import { BulkUploadProvider } from 'contexts/providers/BulkUploadProvider'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

type Props = {
  children: React.ReactNode
}

export const Providers = ({ children }: Props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BulkUploadProvider>
          <AccessProvider>{children}</AccessProvider>
        </BulkUploadProvider>
      </AuthProvider>
    </QueryClientProvider>
  )
}
